/** @format */

import React from "react";
import actionType from "./actionType";
const intialState = {
  isLogin: false,
};
const loginReducer = (state = intialState, action: any) => {
  if (actionType.IS_LOGIN === action.type) {
    return { ...state, isLogin: action.payload };
  } else {
    return state;
  }
};
export default loginReducer;
