/** @format */
/** @format */

import { useRef, useEffect, useState } from "react";
import { AudioMeter } from "../../audioMeter/AudioMeter";

function animateBars(
  analyser,
  canvas,
  canvasCtx,
  dataArray,
  bufferLength,
  setFreq
) {
  analyser.getByteFrequencyData(dataArray);
  const HEIGHT = canvas.height / 2;
  let barHeight = 1;
  let x = 0;

  for (let i = 0; i < 150; i++) {
    canvasCtx.fillStyle = "#ff2e56";
    canvasCtx.fillRect(x, 75, 5, 1.5);
    barHeight = (dataArray[i] / 255) * HEIGHT;
    canvasCtx.fillRect(x, 75, 2, barHeight / 1.5);
    canvasCtx.fillRect(x, 75, 2, -barHeight / 1.5);
    setFreq(barHeight);

    x += 3;
  }
}
interface props {
  analyzerData?: any;
}
const WaveUi = ({ analyzerData }: props) => {
  const canvasRef = useRef(null);
  const [freq, setFreq] = useState(0);

  const dataArray = analyzerData?.dataArray;
  const analyzer = analyzerData?.analyzer;
  const bufferLength = analyzerData?.bufferLength;
  const draw = (dataArray, analyzer, bufferLength) => {
    const canvas = canvasRef.current;
    if (!canvas || !analyzer) return;
    const canvasCtx = canvas.getContext("2d");

    const animate = () => {
      requestAnimationFrame(animate);
      // eslint-disable-next-line no-self-assign
      canvas.width = canvas.width;
      animateBars(
        analyzer,
        canvas,
        canvasCtx,
        dataArray,
        bufferLength,
        setFreq
      );
    };
    animate();
  };

  useEffect(() => {
    draw(dataArray, analyzer, bufferLength);
  }, [dataArray, analyzer, bufferLength]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        height: "140px",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <canvas
        style={{
          borderInline: "2px solid black",
          width: "170px",
          height: "50px",
          marginRight: "10px",
        }}
        ref={canvasRef}
        className='wave'
      />

      <AudioMeter frequency={freq} />
    </div>
  );
};

export default WaveUi;
