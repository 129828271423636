/** @format */
export const API_CONFIG = {
  baseUrl: `${process.env.REACT_APP_BASE_URL}`,
  path: {
    list: 'project/list',
    project: 'project',
    login: 'auth/login',
    chat: 'project/chat',
  },
};
export const getUrl = (url: string, params?: any): string => {
  let urlString = `${API_CONFIG.baseUrl}/${url}`;
  const url1 = new URLSearchParams(params);
  if (params && Object.keys(params).length > 0) {
    urlString += `?${url1}`;
  }
  return urlString;
};
export function convertToMinutesAndSeconds(timeInSeconds) {
  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor((timeInSeconds % 3600) / 60);
  const seconds = (timeInSeconds % 60).toFixed(0);

  if (hours > 0) {
    return `${hours} h ${minutes} m ${seconds} s`;
  } else {
    return `${minutes} m ${seconds} s`;
  }
}
