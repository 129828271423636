import { useState, useEffect, useCallback } from 'react';

const useDebounce = (callback, delay) => {
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const debouncedFunction = useCallback(
    (...args) => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }

      const newTimeout = setTimeout(async () => {
        await callback(...args);
      }, delay);

      setDebounceTimeout(newTimeout);
    },
    [callback, delay, debounceTimeout]
  );

  useEffect(() => {
    return () => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
    };
  }, [debounceTimeout]);

  return debouncedFunction;
};

export { useDebounce };
