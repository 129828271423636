/* eslint-disable no-useless-escape */
export function findClosestTimestamp(speechToText: any, trueValueScore: any) {
  // Helper function to preprocess text
  const preprocessText = (text) => {
    return text
      .toLowerCase() // Convert to lowercase
      .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "") // Remove punctuation
      .replace(/\s{2,}/g, " ") // Remove extra spaces
      .trim(); // Trim leading/trailing whitespace
  };

  // Function to calculate Jaccard Similarity between two strings
  const getJaccardSimilarity = (a, b) => {
    const aSet = new Set(a.split(" "));
    const bSet = new Set(b.split(" "));
    const intersection = new Set([...aSet].filter((x) => bSet.has(x)));
    const union = new Set([...aSet, ...bSet]);
    return intersection.size / union.size;
  };

  // Function to find the closest match based on Jaccard Similarity
  const findClosestMatch = (text) => {
    let closestTimestamp = null;
    let highestSimilarity = -Infinity;
    const preprocessedText = preprocessText(text);

    for (const [timestamp, data] of Object.entries(speechToText.data)) {
      const keyVal: any = data;
      const preprocessedSpeechText = preprocessText(keyVal.text);
      const similarity = getJaccardSimilarity(
        preprocessedText,
        preprocessedSpeechText
      );
      if (similarity > highestSimilarity) {
        highestSimilarity = similarity;
        closestTimestamp = timestamp;
      }
    }
    console.log(closestTimestamp);

    return closestTimestamp;
  };

  const results = {};
  const uniqueTimestamps = new Set();

  for (const [key, value] of Object.entries(trueValueScore)) {
    const text = value[1];
    const closestTimestamp = findClosestMatch(text);

    // Convert the closest timestamp to a unique whole number
    let uniqueWholeNumber = Math.round(parseFloat(closestTimestamp));

    // Ensure uniqueness
    while (uniqueTimestamps.has(uniqueWholeNumber)) {
      uniqueWholeNumber++;
    }

    uniqueTimestamps.add(uniqueWholeNumber);
    results[key] = uniqueWholeNumber;
  }

  return results;
}

export function findNearestTimestampsFromThumbnails(thumbnails, timeStampArr) {
  const thumbnailKeys = Object.keys(thumbnails).map(Number);
  const nearestTimestamps = [];

  timeStampArr.forEach((ts) => {
    const nearest = thumbnailKeys.reduce((prev, curr) => {
      return Math.abs(curr - ts) < Math.abs(prev - ts) ? curr : prev;
    });

    thumbnails[`${nearest}`].link[0];
    nearestTimestamps.push(nearest);
  });

  return nearestTimestamps
    .sort((a, b) => a - b)
    .map((timeStamp) => thumbnails[`${timeStamp}`].link[0]);
}
