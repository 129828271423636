/** @format */

import {
  Card,
  Box,
  Table,
  Center,
  Loader,
  Button,
  Grid,
  Text,
  useMantineTheme,
  TextInput,
  Input,
  Flex,
  Textarea,
  Pagination,
  ActionIcon,
} from '@mantine/core';

import {
  Add,
  Delete,
  KeyboardArrowDownOutlined,
  LinkRounded,
  MovieCreation,
  Refresh,
  RemoveRedEye,
} from '@mui/icons-material';

import { useNavigate } from 'react-router-dom';
import { SetStateAction, useEffect, useState } from 'react';
import { users } from '../../assets/userData';
import CustomModal from '../../component/customModal';
import { Dropzone } from '@mantine/dropzone';
import { Formik } from 'formik';
import projectValidationSchema from '../../constant/validation';
import {
  createProjectReq,
  deleteProjectReq,
  getListReq,
  searchFromList,
} from './service/service';
import { bindActionCreators } from 'redux';
import { LoginAction } from '../login/redux';
import { useDispatch } from 'react-redux';
import { useDebounce } from '../../hooks';

let interval;
const Dashboard = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [projectList, setProjectList] = useState<any>([]);
  const [search, setSearch] = useState('');
  const [opened, setOpened] = useState(false);
  const [isSubmitted, setSubmitted] = useState(true);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const option = [
    { label: 'Select Project Type', value: '', disable: true },
    { label: 'YouTube', value: 'youtube', disable: false },
    { label: 'Media File', value: 'link', disable: false },
  ];
  const [initialValues, setInitialValues] = useState({
    projectName: '',
    type: '',
    projectSrc: '',
    description: '',
  });

  const theme = useMantineTheme();
  useEffect(() => {
    getListReq()
      .then((res) => {
        setLoading(false);
        setProjectList(res);
      })
      .catch((err) => {
        navigate('/login');
      });
  }, []);

  const handleDelete = async (id) => {
    try {
      const res = await deleteProjectReq(id);
      setProjectList(res);
    } catch (error) {
      window.alert(error);
    }
  };

  const rows = projectList
    .filter((project: { projectName: string }) => {
      if (project.projectName === '') {
        return project;
      } else if (project.projectName.toLocaleLowerCase().includes(search)) {
        return project;
      }
    })
    .slice(startIndex, endIndex)
    .map((element: any, index: number) => (
      <tr key={index}>
        <td width={250} align="center">
          <Text>{element.projectName}</Text>
        </td>

        <td width={500}>
          <Text
            my={'xs'}
            size={'sm'}
            color={element.status === 'success' ? 'green' : 'red'}
          >
            {element.projectDescription}
          </Text>
        </td>
        <td align="center">
          <Text>{element.type}</Text>
        </td>
        <td align="center">
          <Text
            style={{
              color: `${
                element.status === 'Completed'
                  ? 'green'
                  : element.status === 'Failed'
                  ? 'red'
                  : 'rgb(0, 30, 107)'
              }`,
            }}
          >
            {element.status}
            {/* {element.status === 'Completed' && !element?.isSaveEmbedding
              ? 'Processing'
              : element.status === 'Completed' && !element?.isSaveEmbedding} */}
          </Text>
        </td>
        <td align="center">
          <Text
            style={{
              color: `${
                element.status === 'Completed' && element?.isSaveEmbeddings
                  ? 'green'
                  : element.status === 'Failed' && !element?.isSaveEmbeddings
                  ? 'red'
                  : 'rgb(0, 30, 107)'
              }`,
            }}
          >
            {(element.status === 'Completed' ||
              element.status === 'Initialising') &&
            !element?.isSaveEmbeddings
              ? 'Processing'
              : element.status === 'Failed' && !element?.isSaveEmbeddings
              ? 'Failed'
              : element.status === 'Completed' && element?.isSaveEmbeddings
              ? 'Available'
              : 'Initialising'}
          </Text>
        </td>
        <td style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            title="Remove from channel"
            styles={{ root: { backgroundColor: 'white !important' } }}
            variant={theme.colorScheme === 'dark' ? 'subtle' : 'white'}
            color={'dark'}
            disabled={element.status !== 'Completed' ? true : false}
            onClick={() => {
              if (element.status === 'Completed') {
                clearInterval(interval);
                navigate(`/projectDetail?id=${element._id}`);
              }
            }}
          >
            <RemoveRedEye />
          </Button>

          <Button
            title="Remove from channel"
            variant={theme.colorScheme === 'dark' ? 'subtle' : 'white'}
            p={5}
            color="dark"
          >
            <Delete
              style={{ color: 'red' }}
              onClick={() => {
                handleDelete(element._id);
              }}
            />
          </Button>
        </td>
      </tr>
    ));
  const handleSubmit = async (value) => {
    setSubmitted(false);
    const formValue = {
      projectDescription: value.description,
      projectName: value.projectName,
      projectLabels: '',
      type: value.type,
      link: value.type === 'youtube' ? value.projectSrc : '',
      video_file: value.type === 'link' ? value.projectSrc : '',
    };

    const data = new FormData();

    Object.entries(formValue).map(([keyName, value]: any) => {
      data.append(keyName, value);
    });
    createProjectReq(data).then((res: any) => {
      setOpened(false);
      setSubmitted(true);
      return res;
    });
  };

  const handleRefresh = () => {
    getListReq()
      .then((res) => {
        setProjectList(res);
      })
      .catch((err) => {
        console.log(err);
        clearInterval(interval);
      });
  };

  const handleModel = () => {
    setOpened(true);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const fetchProjects = async (filterText: SetStateAction<string>) => {
    try {
      const res = await searchFromList(filterText);
      console.log(res);

      setProjectList(res);
    } catch (error) {
      window.alert(error);
    }

    setSearch(filterText);
  };
  const debouncedFetchProjects = useDebounce(fetchProjects, 500);

  return (
    <>
      <CustomModal isOpen={opened} onClose={() => setOpened(false)}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={projectValidationSchema}
        >
          {({
            values,
            touched,
            handleBlur,
            handleChange,
            errors,
            setFieldValue,
            handleSubmit,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <fieldset style={{ border: 'none' }}>
                  <Input.Wrapper
                    label="Project name"
                    m={10}
                    styles={{ label: { fontWeight: 'bold' } }}
                  >
                    <TextInput
                      type="text"
                      name="projectName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.projectName && errors.projectName
                          ? `${errors.projectName}`
                          : null
                      }
                    />
                  </Input.Wrapper>
                  <Input.Wrapper
                    label="Description"
                    m={10}
                    styles={{ label: { fontWeight: 'bold' } }}
                  >
                    <Textarea
                      placeholder="Add Description..."
                      name="description"
                      minRows={2}
                      maxRows={4}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.description && errors.description
                          ? `${errors.description}`
                          : null
                      }
                    />
                  </Input.Wrapper>
                  <Input.Wrapper
                    m={10}
                    label="Project Type"
                    styles={{ label: { fontWeight: 'bold' } }}
                  >
                    <Input
                      component="select"
                      placeholder="Select project type"
                      name="type"
                      value={values.type}
                      error={
                        touched.type && errors.type ? `${errors.type}` : null
                      }
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('projectSrc', '');
                      }}
                      onBlur={handleBlur}
                      rightSection={<KeyboardArrowDownOutlined />}
                    >
                      {option.map((val, ind) => {
                        return (
                          <option
                            disabled={val.disable}
                            style={{ padding: '25px', height: '50px' }}
                            key={ind}
                            value={val.value}
                          >
                            {val.label}
                          </option>
                        );
                      })}
                    </Input>
                  </Input.Wrapper>
                  {values.type === 'link' && (
                    <Input.Wrapper
                      m={10}
                      mt={20}
                      styles={{ label: { fontWeight: 'bold' } }}
                    >
                      <Dropzone
                        accept={[
                          'video/x-msvideo',
                          'video/mp4',
                          'video/mpeg',
                          'video/ogg',
                          'video/mp2t',
                          'video/webm',
                          'video/3gpp',
                          'video/3gpp2',
                        ]}
                        onDrop={(e) => {
                          setFieldValue('projectSrc', e[0]);
                        }}
                        onBlur={handleBlur}
                        name="projectSrc"
                      >
                        {values.projectSrc === '' && (
                          <Text align="center">Drop Video here</Text>
                        )}
                        {values.projectSrc !== '' && (
                          <Flex>
                            <div>
                              <MovieCreation />
                            </div>
                            <Text align="center">{values.projectSrc.name}</Text>
                          </Flex>
                        )}
                      </Dropzone>
                    </Input.Wrapper>
                  )}
                  {values.type === 'youtube' && (
                    <Input.Wrapper
                      m={10}
                      label="YouTube Link"
                      styles={{ label: { fontWeight: 'bold' } }}
                    >
                      <TextInput
                        value={values.projectSrc}
                        name="projectSrc"
                        placeholder="paste Your YouTube link here"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.projectSrc && errors.projectSrc
                            ? `${errors.projectSrc}`
                            : null
                        }
                        rightSection={<LinkRounded />}
                      />
                    </Input.Wrapper>
                  )}
                  <Input.Wrapper m={10} mt={30}>
                    <Button
                      type="submit"
                      variant="filled"
                      leftIcon={
                        !isSubmitted ? (
                          <Loader size={20} color="#ffffff" />
                        ) : null
                      }
                    >
                      Upload
                    </Button>
                  </Input.Wrapper>
                </fieldset>
              </form>
            );
          }}
        </Formik>
      </CustomModal>
      <Card
        shadow="xs"
        p="lg"
        radius="md"
        withBorder
        style={{ textAlign: 'left', minHeight: 'calc(100vh - 185px)' }}
      >
        <Grid my={'2px'} gutter={'xs'}>
          <Grid.Col sm={6} md={4} lg={4}>
            <TextInput
              autoFocus
              placeholder="Search"
              icon={<i className="bx bx-search-alt" />}
              onChange={(e) => debouncedFetchProjects(e.target.value)}
            />
          </Grid.Col>
          <Grid.Col sm={0} md={4} lg={6}></Grid.Col>
          <Grid.Col sm={6} md={4} lg={2}>
            <Flex justify={'right'} align={'center'}>
              <ActionIcon variant="gradient" mx={10} onClick={handleRefresh}>
                <Refresh />
              </ActionIcon>

              <Button
                variant="gradient"
                style={{ float: 'right' }}
                leftIcon={<Add />}
                onClick={handleModel}
              >
                Create Project
              </Button>
            </Flex>
          </Grid.Col>
        </Grid>
        <Box>
          <Table>
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>Project Name</th>
                <th>Description</th>
                <th style={{ textAlign: 'center' }}>Type</th>
                <th style={{ textAlign: 'center' }}>Status</th>{' '}
                <th style={{ textAlign: 'center' }}>Chat</th>{' '}
                <th style={{ display: 'flex', justifyContent: 'center' }}>
                  Actions
                </th>
              </tr>
            </thead>
            {isLoading ? (
              <tbody>
                <tr>
                  <td colSpan={4}>
                    <Center style={{ minHeight: 'calc(100vh - 386px)' }}>
                      <Loader variant="bars" />
                    </Center>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>{rows}</tbody>
            )}
          </Table>
          <br />
          <br />
          <Pagination
            value={currentPage}
            onChange={handlePageChange}
            total={Math.ceil(projectList.length / itemsPerPage)}
          />
        </Box>
      </Card>
    </>
  );
};

export default Dashboard;
