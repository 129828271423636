/** @format */

import React, { ReactNode, useEffect, useState } from 'react';
import '../sentiments/style.css';

interface Props {
  data: any;
}

const Sentiment = ({ data }: Props) => {
  let count = 0;
  const sentimentObj = [...new Set(data)].filter(
    (f) => f !== data[data.length - 1]
  ) as any[];
  sentimentObj.push(data[data.length - 1]);
  const [lastIndex, setLastIndex] = useState(-1);

  return (
    <div className="sentiment-contr" id="sentiment-contr">
      {sentimentObj.map((val, key) => {
        return (
          <div
            key={key}
            className={`sentimentText sentiment fade-in`}
            onAnimationStartCapture={() => {
              const sentimentSize =
                document.querySelectorAll('.sentiment')[0].clientHeight;
              count = 0;

              const contr = document.getElementById(
                'sentiment-contr'
              ) as HTMLDivElement;
              contr.scrollTop = contr.scrollHeight + 15;
              contr.scrollTop = 0;
              contr.style.height = `${
                [...sentimentObj].length * sentimentSize
              }px`;
            }}
          >
            {val}
          </div>
        );
      })}
    </div>
  );
};

export default Sentiment;
