/** @format */

import React, { useEffect } from "react";
import "./carouselStyle.css";
import left from "../../assets/svg/leftArrow.svg";
import playIcon from "../../assets/svg/playIcon.svg";
import { Carousel } from "@mantine/carousel";
import { useSelector } from "react-redux";

interface props {
  img: any;
  handlePlay: any;
}
function CarouselFrame({ img, handlePlay }: props) {
  const isAudioReady = useSelector(
    (state: any) => state.projectReducer.isAudioDownload
  );
  return (
    isAudioReady && (
      <div className={"carousel-section"}>
        <button className='left-btn'>
          <img src={left} width={35} height={75} />
        </button>
        <div className={"carousel-contr "}>
          <Carousel
            height={90}
            slideSize='145px'
            align='start'
            styles={{
              control: {
                display: "none",
              },
            }}
          >
            {Object.entries(img).map(([fileName, imgFile]: any, ind) => {
              return (
                <Carousel.Slide className='slide ' key={ind}>
                  <div style={{ width: "100px" }}>
                    <img src={imgFile?.link[0]} style={{ width: "145px" }} />
                    <div
                      className='slideOverlay'
                      onClick={(e) => {
                        handlePlay(e, Number(fileName));
                      }}
                    >
                      <img
                        src={playIcon}
                        width={25}
                        height={25}
                        style={{ zIndex: "2" }}
                      />
                    </div>
                  </div>
                </Carousel.Slide>
              );
            })}
          </Carousel>
        </div>
      </div>
    )
  );
}

export default CarouselFrame;
