/** @format */

import { useEffect, useState } from "react";

import WaveUi from "./waveUi";

import { useDispatch } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { projectActions } from "../../feature/projectDetail/redux";

interface props {
  path?: string;
  isPlay?: boolean;
  timeStamp?: number;
  click?: any;
}
export default function Wave({ path, isPlay, timeStamp, click }: props) {
  const [analyzerData, setAnalyzerData] = useState(null);
  const [audio, setAudio] = useState(new Audio());

  useEffect(() => {
    audio.pause();
  }, []);

  const [isFirstTime, setFirstTime] = useState(true);
  const dispatch = useDispatch();
  const { audioReq } = bindActionCreators(projectActions, dispatch);
  useEffect(() => {
    const request = new XMLHttpRequest();
    request.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {
        const url = URL.createObjectURL(request.response);
        setAudio(new Audio(url));
        audioReq(true);
      }
    };
    request.open("GET", path, true);
    request.responseType = "blob";
    request.send();
  }, []);
  addEventListener("popstate", () => {
    audio.currentTime = 0;
    audio.pause();
  });
  useEffect(() => {
    if (isPlay) {
      audio.pause();
      audio.currentTime = timeStamp;
      audio.play();
      if (isFirstTime) {
        audioAnalyzer();
        setFirstTime(false);
      }
    } else {
      audio.pause();
      audio.currentTime = timeStamp;
    }
  }, [isPlay, timeStamp, click]);

  const audioAnalyzer = () => {
    const audioCtx = new (window.AudioContext ||
      (window as any).webkitAudioContext)();
    const analyzer = audioCtx.createAnalyser();
    analyzer.fftSize = 1024;

    const bufferLength = analyzer.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    const source = audioCtx.createMediaElementSource(audio);
    source.connect(analyzer);
    source.connect(audioCtx.destination);
    source.onended = () => {
      source.disconnect();
    };

    setAnalyzerData({ analyzer, bufferLength, dataArray });
  };

  return (
    <div className='analyzer'>
      <WaveUi analyzerData={analyzerData} />
      <div
        style={{
          height: 40,
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      ></div>
    </div>
  );
}
