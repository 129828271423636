/* eslint-disable react/prop-types */
import React from "react";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from "recharts";

const Graph = ({ data }) => {
  const graphData = data.map((val) => ({
    name: val.text.replaceAll(" ", "\n"),
    value: val.value,
  }));

  const renderCustomizedLabel = (props) => {
    const { x, y, width, value } = props;
    const radius = 14;

    return (
      <g>
        <circle
          cx={x + width / 2}
          cy={y - radius / 2}
          r={radius}
          fill="#001e6b"
        />
        <text
          x={x + width / 2}
          y={y - radius / 2}
          fill="#fff"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {value}
        </text>
      </g>
    );
  };

  return (
    <ResponsiveContainer width={1200} height={300}>
      <ComposedChart
        data={graphData}
        margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
      >
        <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis domain={[0, 10]} tick={{ fontSize: 12 }} />
        <Bar dataKey="value" fill="#cccccc" barSize={15}>
          <LabelList dataKey="value" content={renderCustomizedLabel} />
        </Bar>
        <Line type="linear" dataKey="value" stroke="#061f10" />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default Graph;
