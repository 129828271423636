/** @format */

import React, { useEffect, useState } from 'react';

interface Props {
  text: string;
  isPlay?: any;
  interval?: number;
  prevText: string;
}

let isSpanStart = true;
let currentText = '';
let currentIndex = 0;
let typingInterval;
function TextTyped({ text, isPlay, interval, prevText }: Props) {
  const [displayText, setDisplayText] = useState('');

  useEffect(() => {
    isSpanStart = true;
    currentText = '';
    currentIndex = 0;
    setDisplayText('');
  }, [isPlay]);

  useEffect(() => {
    currentText = prevText;
    currentIndex = 0;
    if (text) {
      typingInterval = setInterval(() => {
        if (text[currentIndex] === '<' && isSpanStart) {
          const spanStart = '<span class="highlight-punctuation">';
          const spanEnd = '</span>';
          currentText += spanStart;
          currentIndex += spanStart.length;
          isSpanStart = false;
        } else if (text[currentIndex] === '<' && !isSpanStart) {
          const spanStart = '<span class="highlight-punctuation">';
          const spanEnd = '</span>';
          currentText += spanEnd;
          currentIndex += spanEnd.length;
          isSpanStart = true;
        }

        currentText += text[currentIndex];
        currentIndex++;

        setDisplayText(currentText);

        if (currentIndex === text.length) {
          clearInterval(typingInterval);
        }
      }, interval);

      return () => {
        clearInterval(typingInterval);
      };
    }
  }, [text]);
  return <div>{currentText}</div>;
}

export default TextTyped;
