/**
 * eslint-disable no-useless-escape
 *
 * @format
 */

import * as Yup from "yup";

export const loginSchema = Yup.object({
  username: Yup.string().required("Please Enter Your Username"),
});
