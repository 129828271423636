/** @format */

import { API_CONFIG } from "../../../constant/constant";
import httpService from "../../../http/http";

export const postLoginReq = (data) => {
  return new Promise((resolve, reject) => {
    httpService
      .post(
        API_CONFIG.path.login,
        data,
        {},
        { contentType: "application/json" }
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("Error : ", error);
        reject(error);
      });
  });
};
