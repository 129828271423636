/** @format */
import axios, { AxiosResponse } from "axios";
import { getUrl } from "../constant/constant";

const axiosInstance = axios.create();
const CancelToken = axios.CancelToken;
let cancel_req: any;

export { cancel_req };

export interface AxiosParams {
  method: string;
  url: string;
  data?: any;
  isAccessTokenRequire?: boolean;
  contentType?: string;
  Authorization?: string;
}

export interface MiscellaneousRequestParams {
  isAccessTokenRequire?: boolean;
  contentType?: string;
  Authorization?: string;
}

const get = (
  url: string,
  params: any = {},
  otherData: MiscellaneousRequestParams = {}
) => {
  return commonAxios({ method: "GET", url: getUrl(url, params), ...otherData });
};

const patch = (
  url: string,
  params: any = {},
  otherData: MiscellaneousRequestParams = {}
) => {
  return commonAxios({
    method: "PATCH",
    url: getUrl(url, params),
    ...otherData,
  });
};

const post = (
  url: string,
  params: any = {},
  queryParams = {},
  otherData: MiscellaneousRequestParams = {}
) => {
  return commonAxios({
    method: "POST",
    url: getUrl(url, queryParams),
    data: params,
    ...otherData,
  });
};

const put = (
  url: string,
  params: any = {},
  queryParams = {},
  otherData: MiscellaneousRequestParams = {}
) => {
  return commonAxios({
    method: "PUT",
    url: getUrl(url, queryParams),
    data: params,
    ...otherData,
  });
};

const deleteRequest = (
  url: string,
  params: any = {},
  queryParams = {},
  otherData: MiscellaneousRequestParams = {}
) => {
  return commonAxios({
    method: "DELETE",
    url: getUrl(url, queryParams),
    data: params,
    ...otherData,
  });
};

const commonAxios = ({
  method,
  url,
  data,
  isAccessTokenRequire = true,
  contentType = "application/json",
  Authorization = "",
}: AxiosParams): Promise<any> => {
  const headers: any = {
    // "Content-Type": contentType,
  };
  const token =
    // isAccessTokenRequire
    isAccessTokenRequire &&
    (Authorization || localStorage.getItem("access_token"));

  if (token) {
    headers["access_token"] = token;
  } /* else {
    headers["x-request-language"] = localStorage.getItem("lang") || "de";
  } */
  let body: string | any | null = null;
  if (contentType === "application/json") {
    body = data;
  } else {
    body = data as any;
  }

  return new Promise((resolve, reject) => {
    axiosInstance({
      method: method,
      url: url,
      headers: headers,
      data: body,
    })
      .then((response: AxiosResponse<any>) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const httpService = {
  get: get,
  post: post,
  put: put,
  deleteRequest: deleteRequest,
  patch: patch,
  // patch: patch,
};

export { axiosInstance };

export default httpService;
