/** @format */

import { useEffect, useState, useMemo } from "react";
import { AppShell, Box, Button, Card, useMantineTheme } from "@mantine/core";
import Sidenav from "./sidenav";
import TopHeader from "./topHeader";
import { Outlet } from "react-router-dom";

interface Props {
  children?: React.ReactNode;
}

interface MemoProps {
  open: boolean;
  setOpened: (status: boolean) => void;
}

const MemorisedSideNav = (props: MemoProps) =>
  useMemo(
    () => <Sidenav open={props.open} setOpened={props.setOpened} />,
    [props.open]
  );

const Layout = (props: Props) => {
  const { children } = props;

  const theme = useMantineTheme();

  const [opened, setOpened] = useState(false);

  return (
    <AppShell
      styles={{
        main: {
          background:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      }}
      navbarOffsetBreakpoint='sm'
      asideOffsetBreakpoint='sm'
      navbar={<MemorisedSideNav open={opened} setOpened={setOpened} />}
      header={
        <TopHeader open={opened} toggleSidenav={setOpened} theme={theme} />
      }
    >
      <Outlet />
    </AppShell>
  );
};

export default Layout;
