/** @format */

import { ActionType } from "./actionType";

export const audioReqAction = (data: any) => {
  return {
    type: ActionType.AUDIO_DOWNLOAD,
    payload: data,
  };
};
