/* eslint-disable react/jsx-key */
import { useState } from "react";
import { Navbar, Button } from "@mantine/core";
import NAV_OPTIONS from "../../constant/nav";
import { useLocation, useNavigate } from "react-router-dom";

interface Props {
  open: boolean;
  setOpened: (status: boolean) => void;
}

export default function Sidenav(props: Props) {
  const { open, setOpened } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const getActiveNav = (currentRoute: string) => {
    return "/" + location.pathname.split("/")[1] === currentRoute
      ? "gradient"
      : "default";
  };
  return (
    <Navbar
      p="md"
      hiddenBreakpoint="sm"
      hidden={!open}
      style={{ textAlign: "left" }}
      width={{ sm: 200, lg: 200 }}
    >
      {Object.keys(NAV_OPTIONS).map((nav, ind) => {
        const { label, path, icon } = NAV_OPTIONS[nav];
        return (
          <Button
            key={ind}
            variant={getActiveNav(path)}
            size="sm"
            radius="sm"
            mb={"sm"}
            onClick={() => {
              navigate(path);
              setOpened(false);
            }}
            leftIcon={icon}
          >
            {label}
          </Button>
        );
      })}
    </Navbar>
  );
}
