/** @format */

import { API_CONFIG, getUrl } from '../../../constant/constant';
import httpService from '../../../http/http';

export const getListReq = () => {
  return new Promise((resolve, reject) => {
    httpService
      .get(API_CONFIG.path.list)
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        console.log('Error : ', error);
        reject(error);
      });
  });
};

export const searchFromList = (search: any) => {
  return new Promise((resolve, reject) => {
    httpService
      .get(`${API_CONFIG.path.list}/${search}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        console.log('Error : ', error);
        reject(error);
      });
  });
};

export const deleteProjectReq = (id: any) => {
  return new Promise((resolve, reject) => {
    httpService
      .deleteRequest(`${API_CONFIG.path.project}/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        console.log('Error : ', error);
        reject(error);
      });
  });
};

export const createProjectReq = (formData) => {
  return new Promise((resolve, reject) => {
    httpService
      .post(
        API_CONFIG.path.project,
        formData,
        {},
        { contentType: 'multipart/form-data;' }
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        console.log('Error : ', error);
        reject(error);
      });
  });
};
