/** @format */

import { Dashboard } from "@mui/icons-material";

export interface NAV_OPTION_INTERFACE {
  path: string;
  label: string;
  icon: JSX.Element;
}

interface NAV_OPTIONS {
  [nav: string]: NAV_OPTION_INTERFACE;
}

const NAV_OPTIONS: NAV_OPTIONS = {
  DASHBOARD: {
    path: "",
    label: "Dashboard",
    icon: <Dashboard />,
  },
};

export default NAV_OPTIONS;
