import React, { useEffect, useRef, useState } from "react";
import "./style.css";
interface props {
  path: string;
  timeStamp: any;
  isPlay: boolean;
  emotion?: any;
  click?: any;
}
let count = 0;
let faceEmotion = "";
const VisionAnalysis = ({
  path,
  timeStamp = 0,
  isPlay,
  emotion,
  click,
}: props) => {
  const videoRef = useRef() as any | HTMLVideoElement;
  useEffect(() => {
    videoRef.current.muted = true;
    videoRef.current.currentTime = timeStamp;

    if (isPlay) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [isPlay, click]);
  useEffect(() => {
    videoRef.current.currentTime = timeStamp;
  }, [timeStamp]);
  if (
    emotion !== "" &&
    emotion !== "no face" &&
    emotion !== undefined &&
    emotion !== "others"
  ) {
    count = 0;
    faceEmotion = emotion;
  } else {
    count++;

    if (count === 10) {
      faceEmotion = "";
      count = 0;
    }
  }

  return (
    <div
      className="visionVideoContr"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <video
        src={path}
        ref={videoRef}
        style={{ width: "100%" }}
        height={158}
      ></video>
      <div className="visionVideoOverlay">
        {faceEmotion !== "" && faceEmotion && (
          <div
            style={{
              width: "50%",
              height: "83%",
              border: "2px solid green",
              display: "flex",
              justifyContent: "start",
              alignItems: "start",
            }}
          ></div>
        )}
        {faceEmotion !== "" && faceEmotion && (
          <p
            style={{
              color: "black",
              fontSize: "20px",
              backgroundColor: "white",
              width: "fit-content",
              margin: "5px",
              paddingInline: "5px",
              fontWeight: "bold",
              borderRadius: "5px",
              top: "0",
              right: "0",
              position: "absolute",
            }}
          >
            {String(faceEmotion[0])?.toUpperCase() + faceEmotion?.slice(1)}
          </p>
        )}
      </div>
    </div>
  );
};

export default VisionAnalysis;
