/** @format */

import { green } from "@mui/material/colors";
import React from "react";
import "./style.css";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
interface props {
  graphData?: any;

  rowGraphData?: any;
  classes?: any;
}

const RoundChart = (props: props) => {
  const { graphData, rowGraphData } = props;
  const totalVal = rowGraphData
    .map((val) => val?.numberOf)
    .reduce((a, b) => a + b, 0);
  const graphDataWithPercentage = graphData.map((val) => {
    let valName = val?.name;
    valName = String(valName)[0].toUpperCase() + valName.slice(1);

    return {
      ...val,
      name: valName,
      percentage: Number(((val?.numberOf / totalVal) * 100).toFixed(0)),
    };
  });

  return (
    <div
      className='roundChart-wrapper'
      style={{
        width: "100%",
        maxHeight: "119px",
        height: "100%",
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        overflowY: "scroll",
        transition: "all 1s ease",
      }}
    >
      {graphDataWithPercentage.map((graph, ind) => {
        return (
          <div
            key={ind}
            style={{
              width: "57px",
              height: "57px",
              marginInline: "7px",
              transition: "all 1s ease",
            }}
          >
            <div className='roundGraph-contr'>
              <CircularProgressbarWithChildren
                value={graph.percentage}
                styles={buildStyles({
                  strokeLinecap: "round",
                  pathColor: "#001e6b",
                  trailColor: "#b4b2b29c",
                })}
              >
                <strong style={{ fontSize: "17px", marginBottom: "20px" }}>
                  {`${graph.percentage}%`}
                </strong>
              </CircularProgressbarWithChildren>
              <p style={{ margin: "0px", textAlign: "center" }}>
                {graph?.name}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RoundChart;
