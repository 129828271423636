/** @format */

import { ActionType } from "./actionType";
const initialState = {
  isAudioDownload: false,
};
const projectReducer = (state = initialState, action: any) => {
  if (action.type === ActionType.AUDIO_DOWNLOAD) {
    return { ...state, isAudioDownload: action.payload };
  } else {
    return { ...state };
  }
};
export default projectReducer;
